import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { AppContextProvider } from "./store/app.context";
import { App as AntdApp, ConfigProvider } from "antd";
import locale from "antd/locale/id_ID";
import "antd/dist/reset.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<AppContextProvider>
		<ConfigProvider
			locale={locale}
			theme={{
				token: {
					colorPrimary: "#01ABE9",
					colorSuccess: "#29D697",
					colorWarning: "#FFC107",
					colorError: "#F2583E",
					colorTextHeading: "#333333",
					colorText: "#333333",
					fontFamily: "QuickSand",
				},
			}}>
			<AntdApp>
				<HashRouter>
					<App />
				</HashRouter>
			</AntdApp>
		</ConfigProvider>
	</AppContextProvider>
);

reportWebVitals();
