/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Form,
	Input,
	Modal,
	App,
	Popconfirm,
	Spin,
	Typography,
	Radio,
	Select,
	Tag,
} from "antd";
import React, { useEffect } from "react";
import {
	requestAddUser,
	requestEditUser,
	requestGetSchoolList,
} from "../../../../services/master.service";

const UserFormModal = ({ user, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const { message, notification, modal } = App.useApp();
	const [role, setRole] = React.useState(null);

	const [form] = Form.useForm();

	useEffect(() => {
		if (user) {
			form.setFieldsValue({
				name: user.name,
				email: user.email,
				phone: user.phone.replace("+62", ""),
			});
		}
	}, [user]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (values.phone.startsWith("0")) {
					values.phone = values.phone.replace("0", "");
				}
				if (values.phone.startsWith("62")) {
					values.phone = values.phone.replace("62", "");
				}
				if (values.phone.startsWith("+62")) {
					values.phone = values.phone.replace("+62", "");
				}
				values.phone = "+62" + values.phone.replace(/\D/g, "");
				if (user) {
					values.id = user._id;
					requestEditUser(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestAddUser(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				width={512}
				title={user === null || user === undefined ? "Tambah Pengguna" : "Ubah Pengguna"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data pengguna?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{user ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="role"
							label="Peran"
							tooltip="Pilih role"
							rules={[
								{
									required: true,
									message: "Pilih role",
								},
							]}
							required>
							<Radio.Group
								onChange={(e) => {
									setRole(e.target.value);
								}}
								options={[
									{ label: "Admin", value: "ADMIN" },
									{ label: "Guru", value: "TEACHER" },
									{ label: "Siswa", value: "STUDENT" },
									{ label: "Wali", value: "PARENT" },
								]}
								style={{ width: "100%" }}
								optionType="button"
								buttonStyle="solid"
							/>
						</Form.Item>
						{role === "PARENT" && (
							<Typography.Text
								type="secondary"
								style={{
									display: "block",
									marginBottom: "16px",
								}}>
								Pengguna dengan tipe wali dapat memiliki lebih dari 1 siswa. Bila anda menambahkan
								pengguna dengan tipe wali namun sudah terdaftar, maka sistem akan menolak permintaan
								penambahan pengguna baru. Anda dapat mengaitkan langsung wali siswa tersebut pada
								detail siswa yang dapat diakses melalui halaman siswa.
							</Typography.Text>
						)}
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Nomor ID"
							name="number"
							rules={[{ required: true, message: "Masukkan nomor ID" }]}>
							<Input style={{ width: "100%" }} placeholder="Nomor ID" />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							label="Nama"
							name="name"
							rules={[{ required: true, message: "Masukkan nama" }]}>
							<Input style={{ width: "100%" }} placeholder="Nama" />
						</Form.Item>
						<Typography.Text
							type="secondary"
							style={{
								display: "block",
								marginBottom: "16px",
							}}>
							Nama yang Anda masukkan akan digunakan sebagai nama akun. Nama ini bisa berbeda dengan
							data yang tertera di kartu identitas.
						</Typography.Text>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="email"
							label="Email"
							rules={[{ required: true, message: "Masukkan email" }]}>
							<Input style={{ width: "100%" }} placeholder="Email" />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="phone"
							label="No. Telepon"
							rules={[{ required: true, message: "Masukkan nomor telepon" }]}>
							<Input
								onChange={(e) => {
									const value = e.target.value;
									if (value.length === 1 && value === "0") {
										e.target.value = "";
									}
								}}
								addonBefore="+62"
								style={{ width: "100%" }}
								placeholder="No. Telepon"
							/>
						</Form.Item>
						<Typography.Text
							type="secondary"
							style={{
								display: "block",
								marginBottom: "16px",
							}}>
							Masukkan nomor telepon tanpa angka 0 di depannya.
						</Typography.Text>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="password"
							label="Password"
							rules={[{ required: !user, message: "Masukkan password" }]}>
							<Input.Password style={{ width: "100%" }} type="password" placeholder="Password" />
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default UserFormModal;
