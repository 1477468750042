import { Descriptions, Modal, Tag, Tooltip, message } from "antd";
import dayjs from "dayjs";
import { format } from "../../../../utils/Formatter";
import { HiOutlineClipboard } from "react-icons/hi2";

const TransactionDetailModal = ({ isVisible, transaction, onClose }) => {
	return (
		<Modal
			width={512}
			title={
				<span
					style={{
						display: "flex",
						alignItems: "center",
					}}>
					{transaction?._id}
					<Tooltip title="Salin Nomor ID">
						<HiOutlineClipboard
							style={{
								cursor: "pointer",
								marginLeft: "2px",
							}}
							onClick={() => {
								navigator.clipboard.writeText(transaction?._id);
								message.success("Berhasil menyalin nomor ID");
							}}
						/>
					</Tooltip>
				</span>
			}
			open={isVisible}
			onCancel={onClose}
			footer={null}>
			{transaction && (
				<>
					<Descriptions size="small" bordered column={1}>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Nominal">
							{format(transaction?.nominal)}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Biaya Lain">
							{format(transaction?.fees.map((fee) => fee.amount).reduce((a, b) => a + b, 0))}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Total">
							{format(
								transaction?.nominal +
									transaction?.fees.map((fee) => fee.amount).reduce((a, b) => a + b, 0)
							)}
						</Descriptions.Item>
					</Descriptions>
					<Descriptions
						size="small"
						style={{
							marginTop: 12,
						}}
						bordered
						column={1}>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Channel">
							{transaction?.channel}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Status">
							{transaction.status === "COMPLETED" ? (
								<Tag color="processing">Ditahan</Tag>
							) : transaction.status === "CREATED" ? (
								<Tag color="warning">Dibuat</Tag>
							) : transaction.status === "SUBMITTED" ? (
								<Tag color="warning">Pengecekan</Tag>
							) : transaction.status === "SETTLED" ? (
								<Tag color="success">Tuntas</Tag>
							) : transaction.status === "FAILED" ? (
								<Tag color="error">Gagal</Tag>
							) : (
								<Tag color="default">Tidak Sah</Tag>
							)}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Tanggal">
							{dayjs(transaction.createdAt).format("DD MMM YYYY")}
						</Descriptions.Item>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Keterangan">
							{transaction?.note}
						</Descriptions.Item>
					</Descriptions>
				</>
			)}
		</Modal>
	);
};

export default TransactionDetailModal;
