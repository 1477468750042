import { Button, Descriptions, Form, Input, Modal, Popconfirm, notification } from "antd";
import { format } from "../../../../utils/Formatter";
import { requestSettleTransaction } from "../../../../services/master.service";

const TransactionSettlementFormModal = ({ isVisible, onClose, onSuccess, transactions }) => {
	const [form] = Form.useForm();

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				values.ids = transactions.map((transaction) => transaction._id);
				console.log(values);

				requestSettleTransaction(values)
					.then((data) => {
						notification.success({
							message: "Berhasil menambahkan pembayaran!",
							description: data.message,
						});
						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						notification.error({
							message: "Gagal menambahkan pembayaran!",
							description: error.message,
						});
					});
			})
			.catch((info) => {});
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<Modal
			width={512}
			title={`Buat Penyelesaian`}
			open={isVisible}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Batal
				</Button>,
				<Popconfirm
					key={2}
					onConfirm={handleSubmit}
					title="Anda yakin akan menambah pembayaran?"
					okText="Ya"
					cancelText="Tidak">
					<Button type="primary">{"Tambah"}</Button>
				</Popconfirm>,
			]}>
			{transactions && (
				<>
					<Descriptions size="small" bordered column={1}>
						<Descriptions.Item
							labelStyle={{
								width: 128,
							}}
							label="Nominal">
							{format(
								transactions?.map((transaction) => transaction.nominal).reduce((a, b) => a + b, 0)
							)}
						</Descriptions.Item>
					</Descriptions>

					<Form layout="vertical" form={form} requiredMark="optional">
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="number"
							label="Number"
							required
							tooltip="Masukkan nominal pembayaran"
							rules={[
								{
									required: true,
									message: "Masukkan nomor seri transfer!",
								},
							]}>
							<Input
								style={{
									width: "100%",
								}}
								placeholder="TRF-XXXX-XXXX-XXXX"
							/>
						</Form.Item>
					</Form>
				</>
			)}
		</Modal>
	);
};

export default TransactionSettlementFormModal;
