import {
	Button,
	Card,
	Descriptions,
	Modal,
	Space,
	Switch,
	Table,
	Tag,
	Tooltip,
	message,
	notification,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useState } from "react";
import { HiOutlineClipboard } from "react-icons/hi2";
import {
	requestEditSchool,
	requestGetModuleList,
	requestShowSchool,
} from "../../../../services/master.service";
import { format } from "../../../../utils/Formatter";

export default function SchoolDetailModal({ isVisible, id, onSuccess, onClose }) {
	const [school, setSchool] = useState(null);
	const [modules, setModules] = useState([]);
	const [activeModules, setActiveModules] = useState([]);
	const [studentQuota, setStudentQuota] = useState(0);

	useEffect(() => {
		if (isVisible && id) {
			fetchSchoolDetail();
			fetchModuleList();
		} else {
			setSchool(null);
			setModules([]);
		}
	}, [isVisible, id]);

	useEffect(() => {
		if (school && modules) {
			setActiveModules(modules.filter((module) => school.modules.includes(module.code)));
			setStudentQuota(school.quotas?.find((q) => q.key === "student")?.value ?? 0);
		} else {
			setActiveModules([]);
			setStudentQuota(0);
		}
	}, [school, modules]);

	const fetchSchoolDetail = async () => {
		requestShowSchool(id)
			.then((response) => {
				setSchool(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const fetchModuleList = async () => {
		requestGetModuleList({})
			.then((response) => {
				setModules(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const handleStatusChange = (code, checked) => {
		const newModules = [...school.modules];
		if (checked) {
			newModules.push(code);
		} else {
			newModules.splice(newModules.indexOf(code), 1);
		}

		requestEditSchool({
			id: school._id,
			modules: newModules,
		})
			.then((response) => {
				fetchSchoolDetail();
				message.success("Berhasil memperbarui modul sekolah");
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {});
	};

	return (
		<Modal
			width={512}
			title={
				<span
					style={{
						display: "flex",
						alignItems: "center",
					}}>
					{school?.name}
				</span>
			}
			open={isVisible}
			onCancel={onClose}
			footer={
				<Button type="primary" onClick={onClose}>
					Close
				</Button>
			}>
			{school && (
				<Space
					direction="vertical"
					style={{
						width: "100%",
					}}>
					<Descriptions column={1} bordered size="small">
						<Descriptions.Item label="NPSN">
							<span
								style={{
									display: "flex",
									alignItems: "center",
								}}>
								{school.number}
								<Tooltip title="Salin Nomor NPSN">
									<HiOutlineClipboard
										style={{
											cursor: "pointer",
											marginLeft: "2px",
										}}
										onClick={() => {
											navigator.clipboard.writeText(school?.number);
											message.success("Berhasil menyalin nomor ID");
										}}
									/>
								</Tooltip>
							</span>
						</Descriptions.Item>
						<Descriptions.Item label="Nama">{school.name}</Descriptions.Item>
						<Descriptions.Item label="Alamat">{school.address}</Descriptions.Item>
						<Descriptions.Item label="Modul aktif">{activeModules.length} modul</Descriptions.Item>
						<Descriptions.Item label="Quota Siswa">
							{school.quotas?.find((q) => q.key === "student")?.value ?? 0} siswa
						</Descriptions.Item>
						<Descriptions.Item label="Total biaya">
							<span>
								{format(
									activeModules.reduce((total, module) => total + module.price.actual, 0) *
										studentQuota
								)}
							</span>
						</Descriptions.Item>
						<Descriptions.Item label="Aktif hingga">
							{dayjs(school.expiredAt).format("DD MMMM YYYY")}{" "}
							{
								// if more than one month, use warning tag. If less than 7 days, use danger tag. Otherwise, use success tag
								dayjs(school.expiredAt).diff(dayjs(), "month") > 1 ? (
									<Tag color="success" style={{ marginRight: 0 }}>
										{dayjs(school.expiredAt).diff(dayjs(), "month")} bulan lagi
									</Tag>
								) : dayjs(school.expiredAt).diff(dayjs(), "day") > 7 ? (
									<Tag color="warning" style={{ marginRight: 0 }}>
										{dayjs(school.expiredAt).diff(dayjs(), "day")} hari lagi
									</Tag>
								) : (
									<Tag color="danger" style={{ marginRight: 0 }}>
										{dayjs(school.expiredAt).diff(dayjs(), "day")} hari lagi
									</Tag>
								)
							}
						</Descriptions.Item>
					</Descriptions>

					<Table
						bordered
						dataSource={modules.map((module) => ({
							...module,
							key: module._id,
							price: module.price.actual,
							status: school.modules.includes(module.code) ? true : false,
						}))}
						columns={[
							{
								title: "Modul",
								dataIndex: "name",
								key: "name",
							},
							{
								title: "Harga",
								dataIndex: "price",
								key: "price",
								render: (price) => <span>{format(price)}</span>,
							},
							{
								title: "Status",
								dataIndex: "status",
								key: "status",
								render: (status, record) => (
									<Switch
										checkedChildren="ON"
										unCheckedChildren="OFF"
										checked={status}
										onChange={(checked) => handleStatusChange(record.code, checked)}
									/>
								),
							},
						]}
						pagination={false}
						size="small"
						style={{
							marginTop: 12,
						}}
					/>
				</Space>
			)}
		</Modal>
	);
}
