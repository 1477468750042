/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Form,
	Input,
	Modal,
	App,
	Popconfirm,
	Spin,
	Upload,
	Select,
	Card,
	Tag,
	Table,
	Space,
	Descriptions,
	Divider,
	InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import {
	requestAddSchool,
	requestEditSchool,
	requestGetModuleList,
} from "../../../../services/master.service";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import imageCompression from "browser-image-compression";
import { format } from "../../../../utils/Formatter";

const SchoolFormModal = ({ school, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const { message, notification, modal } = App.useApp();
	const [logoUrl, setLogoUrl] = useState();
	const [logo, setLogo] = useState();
	const [modules, setModules] = useState([]);

	const [selectedModules, setSelectedModules] = useState([]);

	const [form] = Form.useForm();
	const studentQuota = Form.useWatch("studentQuota", form);

	useEffect(() => {
		if (isVisible) {
			fetchModuleList();
		}
	}, [isVisible]);

	useEffect(() => {
		if (school) {
			form.setFieldsValue({
				number: school.number,
				name: school.name,
				logo: school.logo,
				address: school.address,
				timezone: school.timezone,
				modules: school.modules ?? [],
				studentQuota: school.quotas?.find((quota) => quota.key === "student")?.value ?? 100,
			});
			setLogoUrl(school.logoFile?.url);
			setSelectedModules(school.modules ?? []);
		}
	}, [school]);

	const fetchModuleList = () => {
		requestGetModuleList({})
			.then((response) => {
				setModules(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				const formData = new FormData();
				formData.append("number", values.number);
				formData.append("name", values.name);
				if (logo) {
					formData.append("logo", logo);
				}
				formData.append("address", values.address);
				formData.append("timezone", values.timezone);
				formData.append("modules", values.modules);
				formData.append("studentQuota", values.studentQuota);

				if (school) {
					formData.append("id", school._id);
					requestEditSchool(formData)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestAddSchool(formData)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		setLogoUrl(null);
		setLogo(null);
		setSelectedModules([]);
		onClose();
	};

	const beforeUpload = (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			message.error("You can only upload JPG/PNG file!");
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error("Image must smaller than 2MB!");
		}

		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			var options = {
				maxSizeMB: 0.3,
				maxWidthOrHeight: 1024,
				useWebWorker: true,
			};
			setLogo(file);
			setLogoUrl(reader.result);
			// imageCompression(file, options)
			// 	.then(function (compressedFile) {
			// 		console.log("compressedFile", compressedFile); // true
			// 		setLogoUrl(reader.result);
			// 	})
			// 	.catch(function (error) {
			// 		if (error.response && error.response.data) {
			// 			notification["error"]({
			// 				message: "Kesalahan!",
			// 				description: error.response.data.message,
			// 			});
			// 		} else {
			// 			notification["error"]({
			// 				message: "Kesalahan!",
			// 				description: error.message,
			// 			});
			// 		}
			// 	});
		};

		// then upload `file` from the argument manually
		return false;
	};

	const uploadButton = (
		<div>
			{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div
				style={{
					marginTop: 8,
				}}>
				Upload
			</div>
		</div>
	);

	return (
		<>
			<Modal
				width={512}
				title={school === null || school === undefined ? "Tambah Sekolah" : "Ubah Sekolah"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data sekolah?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{school ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							label="Nomor Pokok Sekolah Nasional (NPSN)"
							style={{ marginTop: 0, marginBottom: 0 }}
							name="number"
							rules={[{ required: true, message: "Masukkan Nomor Pokok Sekolah Nasional (NPSN)" }]}>
							<Input style={{ width: "100%" }} placeholder="1234" />
						</Form.Item>
						<Form.Item
							label="Nama Sekolah"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="name"
							rules={[{ required: true, message: "Masukkan nama" }]}>
							<Input style={{ width: "100%" }} placeholder="Nama" />
						</Form.Item>
						{/* logo with input image */}
						<Form.Item label="Logo Sekolah" style={{ marginTop: 12, marginBottom: 0 }} name="logo">
							<Upload
								name="avatar"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								beforeUpload={beforeUpload}>
								{logoUrl ? (
									<img
										src={logoUrl}
										alt="avatar"
										style={{
											width: "100%",
										}}
									/>
								) : (
									uploadButton
								)}
							</Upload>
						</Form.Item>
						{/* Address */}
						<Form.Item
							label="Alamat"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="address"
							rules={[{ required: true, message: "Masukkan alamat" }]}>
							<Input.TextArea style={{ width: "100%" }} rows={2} placeholder="Alamat" />
						</Form.Item>
						{/* Timezone */}
						<Form.Item
							label="Zona Waktu"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="timezone"
							rules={[{ required: true, message: "Masukkan zona waktu" }]}>
							<Select placeholder="Zona waktu">
								<Select.Option value="">Pilih zona waktu</Select.Option>
								<Select.Option value="Asia/Jakarta">Asia/Jakarta (UTC +7)</Select.Option>
								<Select.Option value="Asia/Makassar">Asia/Makassar (UTC +8)</Select.Option>
								<Select.Option value="Asia/Jayapura">Asia/Jayapura (UTC +9)</Select.Option>
							</Select>
						</Form.Item>
						{/* Multi select modules */}
						<Form.Item
							label="Modul"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="modules"
							rules={[{ required: true, message: "Pilih modul" }]}>
							<Select
								mode="multiple"
								placeholder="Modul"
								onChange={(value) => {
									setSelectedModules(value);
								}}>
								{modules.map((module) => (
									<Select.Option key={module._id} value={module.code}>
										{module.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						{/* Student quota */}
						<Form.Item
							label="Kuota Siswa"
							initialValue={1}
							style={{ marginTop: 12, marginBottom: 0 }}
							name="studentQuota"
							rules={[{ required: true, message: "Masukkan kuota siswa" }]}>
							<InputNumber min={100} style={{ width: "100%" }} placeholder="Kuota siswa" />
						</Form.Item>
					</Form>
					<Divider />
					<Descriptions
						column={1}
						bordered
						size="small"
						style={{
							marginTop: 12,
						}}>
						{modules
							.filter((module) => selectedModules.includes(module.code))
							.map((module) => (
								<Descriptions.Item label={module.name} key={module._id}>
									<span>
										{format(module.price.actual * studentQuota)} @{format(module.price.actual)}
									</span>
								</Descriptions.Item>
							))}
						<Descriptions.Item label="Total">
							<span>
								{format(
									modules
										.filter((module) => selectedModules.includes(module.code))
										.reduce((total, module) => total + module.price.actual, 0) * studentQuota
								)}
							</span>
						</Descriptions.Item>
					</Descriptions>
				</Spin>
			</Modal>
		</>
	);
};

export default SchoolFormModal;
