import React, { useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import AppContext from "./store/app.context";
import axios from "axios";
import RegisterPage from "./pages/auth/RegisterPage";
import LoginPage from "./pages/auth/LoginPage";
import SchoolPage from "./pages/master/schools";
import DashboardPage from "./pages/master/dashboard";
import UserPage from "./pages/master/users";
import TransactionPage from "./pages/master/transactions";
import SubjectPage from "./pages/master/subjects";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import dayjs from "dayjs";
import ModulePage from "./pages/master/modules";
require("dayjs/locale/id");

dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(require("dayjs/plugin/timezone"));

dayjs.locale("id");
dayjs.tz.setDefault("Asia/Jakarta");

function App() {
	const context = useContext(AppContext);
	const navigate = useNavigate();

	axios.defaults.withCredentials = true;
	axios.defaults.headers.get["X-Language"] = "id";
	axios.defaults.headers.get["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.post["X-Language"] = "id";
	axios.defaults.headers.post["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.patch["X-Language"] = "id";
	axios.defaults.headers.patch["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.defaults.headers.delete["X-Language"] = "id";
	axios.defaults.headers.delete["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	axios.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			console.log(error.response);
			if (error.response.status === 401) {
				context.logout();
				navigate("/login");
			}
			return Promise.reject(error);
		}
	);

	return (
		<div className="App">
			<Routes>
				{!context.isLoggedIn && <Route path="/register" exact element={<RegisterPage />} />}
				{!context.isLoggedIn && <Route path="/login" exact element={<LoginPage />} />}
				{!context.isLoggedIn && (
					<Route path="/forgot-password" exact element={<ForgotPasswordPage />} />
				)}

				{/* Admin Start */}
				{context.isLoggedIn && (context.role === "MASTER" || context.role === "ADMIN") && (
					<>
						<Route
							exact
							path="/master/dashboard"
							element={
								<Main>
									<DashboardPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/master/modules"
							element={
								<Main>
									<ModulePage />
								</Main>
							}
						/>
						<Route
							exact
							path="/master/schools"
							element={
								<Main>
									<SchoolPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/master/users"
							element={
								<Main>
									<UserPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/master/transactions"
							element={
								<Main>
									<TransactionPage />
								</Main>
							}
						/>
						<Route
							exact
							path="/master/subjects"
							element={
								<Main>
									<SubjectPage />
								</Main>
							}
						/>
						<Route
							path="/*"
							element={
								context.isLoggedIn ? <Navigate to="/master/dashboard" /> : <Navigate to="/login" />
							}
						/>
					</>
				)}
				{/* Admin End */}

				{/* Default */}
				<Route path="/*" element={<Navigate to="/login" />} />
				{/* Default */}
			</Routes>
		</div>
	);
}

export default App;
